export enum BannerEnum {
  NIGHT_BANNER = "NIGHT_BANNER",
  NY_BANNER = "NY_BANNER",
}

export type BannerRuleType = {
  banner: BannerEnum;
  timeStart: number | Date;
  timeEnd: number | Date;
};

export type BannerBlockType = {
  nightBannerData: Queries.Seo_CommonNightBanner;
}
