import React from "react";

import { Link } from "gatsby";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { Icon, IconTypeEnum } from "@/components/common/icon";
import { cleanupFromTags } from "@/utils/stringUtils";
import { ReservedFiltersEnum } from "@components/catalog/types";

import { filtersToUrl, setOrResetTheOnlyValue } from "../utils/filterUtils";

// eslint-disable-next-line import/no-unresolved
import "swiper/css";
import "./styles.scss";
import SLIDE_ICONS from "./constants";
import { ScopeFilterProps } from "./types";

const ScopeFilter = (props: ScopeFilterProps) => {
  const {
    scopes,
    selected = [],
    selectedFilters,
    onChange,
  } = props;

  const onFilterClick = (valueId: number) => {
    onChange(ReservedFiltersEnum.Scope, setOrResetTheOnlyValue(selected, valueId));
  };

  const stopReloadPage = (event: React.MouseEvent, valueId: number) => {
    event.stopPropagation();
    onFilterClick(valueId);
  };

  return (
    <div className="scope-filter">
      <h2>Сферы вопросов</h2>
      <div className="scope-filter__slider-container">
        <div className="scope-filter__arrow left">
          <Icon type={IconTypeEnum.Left} />
        </div>
        <div className="scope-filter__arrow right">
          <Icon type={IconTypeEnum.Right} />
        </div>
        <Swiper
          modules={[Navigation]}
          slidesPerView="auto"
          breakpoints={{
            768: {
              slidesPerView: 3.3,
            },
            930: {
              slidesPerView: 4.2,
            },
            1200: {
              slidesPerView: 5,
            },
          }}
          navigation={{
            nextEl: ".scope-filter__arrow.right",
            prevEl: ".scope-filter__arrow.left",
            disabledClass: "hide",
          }}
          className="scope-filter__slider"
        >
          {scopes.map((scope, index) => {
            // todo: change scopes[index] to scope
            const slideName = cleanupFromTags(scopes[index]?.name || "");
            return (
              <SwiperSlide key={scopes[index].id} className="scope-filter__card-container">
                <div
                  className={
                  selected.length === 1 && selected[0] === scopes[index].id
                    ? "scope-filter__card selected"
                    : "scope-filter__card"
                }
                  onClick={() =>
                    onFilterClick(scopes[index].id)}
                >
                  <div className="scope-filter__icon-container">
                    <Icon
                      type={SLIDE_ICONS[index]?.type}
                      viewBox={40}
                      className={SLIDE_ICONS[index]?.color}
                    />
                  </div>
                  <div className="scope-filter__text">
                    <div className="scope-filter__header">
                      <Link
                        to={filtersToUrl({ ...selectedFilters, [ReservedFiltersEnum.Scope]: [scopes[index].id] })}
                        dangerouslySetInnerHTML={{ __html: `${slideName}` }}
                        onClick={(event) => {
                          stopReloadPage(event, scopes[index].id);
                        }}
                      />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </div>
  );
};

export default ScopeFilter;
