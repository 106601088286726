export enum ReservedFiltersEnum {
  Scope = "scope",
  Skill = "skill",
  Format = "format",
  Gender = "gender",
  OnlineOnly = "onlineonly",
  Sorting = "sorting",
  Page = "page",
}

export type SelectedFiltersType = {
  [x: string]: number[]; // todo: we might have to change it to string for SEO
};

export type FilterValueType = {
  id: number; // todo: we might have to change it to string for SEO
  name: string;
};

export type SkillValueType = FilterValueType & {
  description: string;
};

export type ScopeValueType = FilterValueType & {
  id: number;
  name: string;
  description: string;
  scopesCount: number;
};

// Aleksandr
// Сделать фильтры: Формат консультации, Стоимость, Пол, Возраст
export type OtherFilterType = {
  id: ReservedFiltersEnum;
  name: string;
  values: FilterValueType[];
};

export enum ExpertGenderEnum {
  Male = "MALE",
  Female = "FEMALE",
}

export type FilterChangeFunction = (filterId: ReservedFiltersEnum, valueIds: number[]) => void;

export type FilterClearFunction = () => void;

export type CatalogSeoUrl = {
  /** SEO-friendly URL */
  url: string;
  /** Skills which selected for this URL */
  skills?: number[];
  /** Scopes which selected for this URL */
  scopes?: number[];
};
