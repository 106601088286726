import React from "react";

import { Helmet } from "react-helmet";

import {
  removeHeadingSlash, removeTrailingSlash, removeTrailingSymbols, trimSlashes,
} from "@/utils/stringUtils";

import { SeoProps } from "./types";

/**
 * Component for meta tags
 * @param param0
 * @returns
 */
const SeoHeaders = ({ title, ogTitle, description, ogDescription, url, imageUrl }: SeoProps) => {
  const canonical = trimSlashes(`${removeTrailingSlash(process.env.GATSBY_WEBSITE_URL || `https://lunaro.ru`)}/${removeHeadingSlash(
    removeTrailingSymbols(url),
  )}`);

  return (
    <Helmet>
      {!!title && <title>{title}</title>}
      {(!!ogTitle || !!title) && <meta property="og:title" content={ogTitle || title} />}
      {!!description && <meta name="description" content={description} />}
      {(!!ogDescription || !!description) && <meta property="og:description" content={ogDescription || description} />}
      {/* eslint-disable-next-line react/no-invalid-html-attribute */}
      {!!imageUrl && <link rel="image_src" href={imageUrl} />}
      {!!imageUrl && <meta property="og:image" content={imageUrl} />}
      {!!imageUrl && <meta name="twitter:image:src" content={imageUrl} />}
      {!!imageUrl && <meta name="twitter:card" content="summary_large_image" />}
      {!!imageUrl && <link as="image" rel="preload" href={imageUrl} fetchpriority="high" />}
      <link rel="canonical" href={canonical} />
    </Helmet>
  );
};

export default SeoHeaders;
