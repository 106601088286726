import React from "react";

import "./styles.scss";
import { SkillBlockProps } from "./types";

const SkillBlock = (props: SkillBlockProps) => {
  const { skills, selectedSkills = [], header } = props;

  // const [isDescriptionShown, setIsDescriptionShown] = useState<boolean>(false);

  if (!skills || skills.length === 0) {
    return <></>;
  }

  // const skill = selectedSkills.length === 1 ? skills.find((s) =>
  //   s.id === selectedSkills[0]) ?? null : null;

  return (
    <div className="skill">
      <h1 className="skill__name">{header || "Каталог экспертов"}</h1>
      {/* <div className={isDescriptionShown ? "skill__desc shown" : "skill__desc"}>
        {skill
          ? skill.description
          : "Вы ищете точную, ясную и чёткую предсказательную систему? Тогда в ясную и
           чёткую предсказательную в ясную и чёткую предсказательную в ясную и чёткую предсказательную
           в ясную и чёткую предсказательную"}
      </div>
      <div className="skill__show-more" onClick={() => setIsDescriptionShown((oldValue) => !oldValue)}>
        {isDescriptionShown ? "Скрыть" : "Показать ещё"}
      </div> */}
    </div>
  );
};

export default SkillBlock;
