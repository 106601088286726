import { DropdownValue } from "@/components/common/dropdown";

import { OtherFilterType, ReservedFiltersEnum } from "../types";

export const PAGE_SIZE = 100;
export const SIDEBAR_FILTERS_DEBOUNCING = 1000;

export const OTHER_FILTERS: OtherFilterType[] = [
  {
    id: ReservedFiltersEnum.Format,
    name: "Формат консультации",
    values: [
      { id: 1, name: "По видео" },
      { id: 2, name: "По телефону" },
      { id: 3, name: "В чате" },
    ],
  },
  {
    id: ReservedFiltersEnum.Gender,
    name: "Пол",
    values: [
      { id: 1, name: "Женщины" },
      { id: 2, name: "Мужчины" },
    ],
  },
];

// Don't change the order in array
// because this will influence backend request in mapSortingIdToRequestValue()
export const SORTING_VALUES: DropdownValue[] = [
  { value: "0", label: "По рейтингу" },
  { value: "1", label: "Сначала низкая цена" },
  { value: "2", label: "Сначала высокая цена" },
];
