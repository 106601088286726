import { MSK_TIME_OFFSET } from "./constants";
import { BannerEnum, BannerRuleType } from "./types";

/**
 * Returns date with spesified UTC time
 * @param date
 * @param hours UTC hours
 * @param minutes UTC minutes
 * @param seconds UTC seconds
 * @returns Date with spesified UTC time
 */
export const getDateWithSpecificUTCTime = (
  date: Date,
  hours: number,
  minutes: number = 0,
  seconds: number = 0,
): Date => {
  const result = new Date(date.getTime());

  // Parameters correction
  const correctedHours = hours >= 0 ? hours % 24 : 24 + (hours % 24);
  const correctedMinutes = minutes >= 0 ? minutes % 60 : 60 + (minutes % 60);
  const correctedSeconds = seconds >= 0 ? seconds % 60 : 60 + (seconds % 60);

  // Setting corrected parameters
  result.setUTCHours(correctedHours, correctedMinutes, correctedSeconds);

  // Fixing day in case of timezone offset
  result.setDate(date.getDate());
  result.setMonth(date.getMonth());
  result.setFullYear(date.getFullYear());

  return result;
};

export const getBannerToShow = (bannerShowRules: BannerRuleType[]): BannerEnum | undefined => {
  const currentDateTime = new Date();
  const ruleToApply = bannerShowRules.find((rule) =>
    (
      currentDateTime >= (rule.timeStart instanceof Date
        ? rule.timeStart : getDateWithSpecificUTCTime(currentDateTime, rule.timeStart))
      && currentDateTime < (
        rule.timeEnd instanceof Date
          ? rule.timeEnd : getDateWithSpecificUTCTime(currentDateTime, rule.timeEnd)
      )
    ))?.banner;
  return ruleToApply;
};

/**
 * Returns banner rules from most important to least. Time format should be Moscow time
 */
export const getBannerShowRules = (
  nightBannerData: Queries.Seo_CommonNightBanner,
): BannerRuleType[] =>
  ([
    {
      banner: BannerEnum.NY_BANNER,
      timeStart: new Date(Date.UTC(2023, 11, 31, 9 - MSK_TIME_OFFSET)),
      timeEnd: new Date(Date.UTC(2024, 0, 1, 12 - MSK_TIME_OFFSET)),
    },
    {
      banner: BannerEnum.NIGHT_BANNER,
      timeStart: Number(nightBannerData.commonNightBannerTimeStartMsk.match(/^\d\d/g)[0]) - MSK_TIME_OFFSET,
      timeEnd: Number(nightBannerData.commonNightBannerTimeStopMsk.match(/^\d\d/g)[0]) - MSK_TIME_OFFSET,
    },
  ]);
