import { gql } from "@apollo/client";

export const GET_REFERRAL_BONUS_STATUS = gql`
  query getReferralBonusStatus{
    getReferralBonusStatus{
      id
      amount
      name
      type
      status
      createdDate
      updatedDate
  }
}
`;
