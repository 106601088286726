import React, { useEffect, useState } from "react";

import { useQuery } from "@apollo/client";

import { Icon, IconSizeEnum, IconTypeEnum } from "@/components/common/icon";

import { getReferralBonusStatus } from "../graphql/__generated__/getReferralBonusStatus";
import { GET_REFERRAL_BONUS_STATUS } from "../graphql/GET_REFERRAL_BONUS_STATUS";
import "./styles.scss";

const ReferralInfo = () => {
  const [showBlock, setShowBlock] = useState<boolean | null>(false);
  const { data: referralBonusStatus, error, loading } = useQuery<getReferralBonusStatus>(GET_REFERRAL_BONUS_STATUS, { fetchPolicy: "network-only" });

  useEffect(() => {
    if (referralBonusStatus && !error && !loading) {
      setShowBlock(!referralBonusStatus.getReferralBonusStatus.status);
    }
  }, [error, loading, referralBonusStatus]);

  return (
    showBlock ? (
      <div className="referral-info-container">
        <div className="referral-info-container__icon-block">
          <Icon type={IconTypeEnum.Gift} size={IconSizeEnum.Size24} />
          5 мин
        </div>

        <p className="referral-info-container__title">
          {`Друг подарил вам ${referralBonusStatus?.getReferralBonusStatus.amount} ${referralBonusStatus?.getReferralBonusStatus.type === "minutes" ? "минут" : "₽"}`}
        </p>

        <p className="referral-info-container__description">Получите их, пополнив баланс от 2500₽.</p>
        <p className="referral-info-container__description">
          А от себя мы дарим вам целую консультацию
          {"\u00a0"}
          —
          {"\u00a0"}
          попробуйте сервис прямо сейчас
        </p>
        <Icon
          className="referral-info-container__close-icon"
          type={IconTypeEnum.Close}
          size={IconSizeEnum.Size24}
          onClick={() =>
            setShowBlock(false)}
        />
      </div>
    ) : <></>
  );
};

export default ReferralInfo;
