import React from "react";

import "./styles.scss";

const Skeleton = () =>
  (
    <div className="skeleton">
      <div className="skeleton__avatar" />
      <div className="skeleton__info">
        <div className="skeleton__name" />
        <div className="skeleton__subinfo" />
        <div className="skeleton__status" />
        <div className="skeleton__description" />
      </div>
      <div className="skeleton__bottom">
        <div className="skeleton__buttons">
          <div className="skeleton__button" />
          <div className="skeleton__button" />
        </div>
      </div>
    </div>
  );

export default Skeleton;
