import { gql } from "@apollo/client";

export const GET_EXPERTS = gql`
  query getExperts($after: String, $first: Int, $filter: ExpertFilter, $sorting: SortParameters) {
    getExperts(after: $after, first: $first, filter: $filter, sorting: $sorting) {
      edges {
        node {
          id
          name
          description
          mobileImage: image(size: 105) {
            onex
            twox
            threex
          }
          desktopImage: image(size: 176) {
            onex
            twox
            threex
          }
          rates {
            videoCall
            phoneCall
            chat
          }
          gender
          state
          rating
          skills {
            badge
          }
          trialAllowance {
            video
            chat
            phone
          }
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
    }
  }
`;
