import React, { useEffect, useState } from "react";

import { changeElementPosition } from "@/utils/arrayUtils";
import { declenateWord } from "@/utils/stringUtils";
import { Button, ButtonColorEnum, ButtonSizeEnum } from "@components/common/button";
import { Icon, IconSizeEnum, IconTypeEnum } from "@components/common/icon";

import { SIDEBAR_FILTERS_DEBOUNCING } from "../catalogContainer/constants";
import { FilterChangeFunction, ReservedFiltersEnum, SelectedFiltersType } from "../types";

import Filter from "./filter";
import { SidebarProps } from "./types";
import "./styles.scss";

const Sidebar = (props: SidebarProps) => {
  const {
    skills,
    scopes,
    otherFilters,
    selectedFilters,
    isMobileFilterOpen,
    expertsCount,
    setIsMobileFilterOpen,
    onChange,
    onFilterClear,
  } = props;

  // local filter for correct debouncing and close, reset, apply mobile filter logic
  const [localFilters, setLocalFilters] = useState<SelectedFiltersType>(selectedFilters);

  const closeModal = () => {
    document.documentElement.style.overflow = "";
    document.body.style.overflow = "";
    setIsMobileFilterOpen(false);
  };

  const resetFilters = () => {
    onFilterClear();
    closeModal();
  };

  // debounce filters
  const filterChangeHandler: FilterChangeFunction = (filterId: ReservedFiltersEnum, valueIds: number[]) => {
    setLocalFilters((oldFilters) =>
      ({ ...oldFilters, [filterId]: valueIds })); // check if valueIds is not mutated
    setTimeout(() =>
      onChange(filterId, valueIds), SIDEBAR_FILTERS_DEBOUNCING);
  };

  // since filters may change not from sidebar only, but via scopes/skills click we should track that
  useEffect(() => {
    setLocalFilters(selectedFilters);
  }, [selectedFilters]);

  const isSeoLinkShown = (selectedFilters[ReservedFiltersEnum.Skill]?.length ?? 0) <= 1
    && (selectedFilters[ReservedFiltersEnum.Scope]?.length ?? 0) <= 1;

  return (
    <aside className={isMobileFilterOpen ? "sidebar is-opened" : "sidebar"}>
      <div className="sidebar__top">
        <h2 className="sidebar__header">Фильтры</h2>
        <Icon className="sidebar__close" onClick={closeModal} type={IconTypeEnum.Close} size={IconSizeEnum.Size32} />
      </div>
      <div className="sidebar__content">
        {otherFilters.map((filter) =>
          (
            <Filter
              id={filter.id}
              name={filter.name}
              values={filter.values}
              selectedValueIds={localFilters[filter.id]}
              selectedFilters={selectedFilters}
              showSeoLink={isSeoLinkShown}
              onChange={filterChangeHandler}
              key={filter.id}
            />
          ))}
        <Filter
          id={ReservedFiltersEnum.Skill}
          name="Категория"
          values={changeElementPosition(skills, 2, 0)}// todo: remove changeElementPosition after https://3.basecamp.com/5069474/buckets/26069459/todos/6586058560
          selectedValueIds={localFilters[ReservedFiltersEnum.Skill]}
          selectedFilters={selectedFilters}
          showSeoLink={isSeoLinkShown}
          onChange={filterChangeHandler}
          key={ReservedFiltersEnum.Skill}
        />
        <Filter
          id={ReservedFiltersEnum.Scope}
          name="Сфера вопросов"
          values={scopes}
          selectedValueIds={localFilters[ReservedFiltersEnum.Scope]}
          selectedFilters={selectedFilters}
          showSeoLink={isSeoLinkShown}
          onChange={filterChangeHandler}
          key={ReservedFiltersEnum.Scope}
        />
      </div>
      <div className="sidebar__bottom">
        <div className="sidebar__buttons">
          <Button
            text="Сбросить"
            color={ButtonColorEnum.Light}
            size={ButtonSizeEnum.Large}
            className="sidebar__reset"
            onClick={resetFilters}
          />
          <Button
            text="Посмотреть"
            color={ButtonColorEnum.Dark}
            size={ButtonSizeEnum.Large}
            subtext={`${expertsCount} ${declenateWord(expertsCount, ["эксперт", "эксперта", "экспертов"])}`}
            className="sidebar__apply"
            onClick={closeModal}
          />
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
