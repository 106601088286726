import React, { FC, useContext } from "react";

import { navigate } from "gatsby";

import UserContext from "@/contexts/User/UserContext";
import { useMarketingHook } from "@/marketing/marketingHook";

import "./styles.scss";
import { ExpertListingBannerProps } from "./types";
import { isUrlExists } from "./utils";

const ExpertListingBanner: FC<ExpertListingBannerProps> = ({ catalogBannerData }) => {
  const {
    catalogBannerDesktopImage,
    catalogBannerMobileImage,
    catalogBannerTabletImage,
    catalogBannerMetric,
    catalogBannerUrl,
  } = catalogBannerData;
  const { marketingStepsForFixPaymentFromLandingPage } = useMarketingHook();
  const { customMarketingHandler } = useMarketingHook();

  const { userID } = useContext(UserContext);
  const onClickHandler = () => {
    if (catalogBannerUrl) navigate(catalogBannerUrl);
    if (catalogBannerMetric?.metricId) {
      customMarketingHandler(catalogBannerMetric?.metricId);
    }
    // todo: create and use SEO admin analytics data when chained analytics is needed
    // marketingStepsForFixPaymentFromLandingPage({
    //   userId: userID?.toString(),
    //   bannerName: "secret_ideal_stretching_banner_5000",
    // });
  };

  return (
    <div className="expert-listing-banner" onClick={onClickHandler}>
      <picture>
        {isUrlExists(catalogBannerMobileImage) && (
          <source
            srcSet={`${catalogBannerMobileImage.oneX.data?.attributes?.url} 1x,
              ${catalogBannerMobileImage.twoX.data?.attributes?.url} 2x,
              ${catalogBannerMobileImage.threeX?.data?.attributes?.url ?? catalogBannerMobileImage.twoX.data?.attributes?.url} 3x`}
            media="(max-width: 767px)"
          />
        )}
        {isUrlExists(catalogBannerTabletImage) && (
          <source
            srcSet={`${catalogBannerTabletImage.oneX.data?.attributes?.url} 1x,
              ${catalogBannerTabletImage.twoX.data?.attributes?.url} 2x,
              ${catalogBannerTabletImage.threeX?.data?.attributes?.url ?? catalogBannerTabletImage.twoX.data?.attributes?.url} 3x`}
            media="(max-width: 1199px)"
          />
        )}
        {isUrlExists(catalogBannerDesktopImage) && (
          <source
            srcSet={`${catalogBannerDesktopImage?.oneX.data?.attributes?.url} 1x,
              ${catalogBannerDesktopImage?.twoX.data?.attributes?.url} 2x,
              ${catalogBannerDesktopImage?.threeX?.data?.attributes?.url ?? catalogBannerDesktopImage?.twoX.data?.attributes?.url} 3x`}
            media="(min-width: 1200px)"
          />
        )}
        <img
          src={catalogBannerMobileImage.oneX.data?.attributes?.url}
          alt=""
          className="expert-listing-banner__image"
        />
      </picture>
    </div>
  );
};

export default ExpertListingBanner;
