import React from "react";

import _uniqueId from "lodash/uniqueId";

import { ReservedFiltersEnum } from "@/components/catalog/types";
import { cleanupFromTags } from "@/utils/stringUtils";
import { updateValue } from "@components/catalog/utils/filterUtils";

import { FilterItemProps } from "./types";
import "./styles.scss";

const FilterItem = (props: FilterItemProps) => {
  const {
    id: filterId, name, value, selectedValueIds = [], onChange,
  } = props;
  const filterUniqueId = _uniqueId();

  const valueChangeHandler = (id: ReservedFiltersEnum, valueId: number) => {
    onChange(id, updateValue(selectedValueIds, valueId));
  };

  return (
    <>
      <input
        type="checkbox"
        id={filterUniqueId}
        value={cleanupFromTags(name)}
        checked={!!selectedValueIds?.includes(value.id)}
        onChange={() =>
          valueChangeHandler(filterId, value.id)}
      />
      <label htmlFor={filterUniqueId}>
        <span
          className="filter-item__value-name"
          dangerouslySetInnerHTML={{
            __html: cleanupFromTags(value.name),
          }}
        />
      </label>
    </>
  );
};

export default FilterItem;
