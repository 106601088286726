import { navigate } from "gatsby";

import { SelectedFiltersType } from "../types";

import { enrichFiltersWithDefaults, filtersToUrl, urlToFilters } from "./filterUtils";

/**
 * Creates observer for catalog parameters that writes them to URL
 * Supports quiet update - update without notifying subscribers
 * @returns
 */
export const createUrlObserver = () => {
  // Getting the initial state from URL
  let state = urlToFilters();
  let listeners: { (data: any): void }[] = [];
  // todo: implement memorizedPage saving on quiet update

  const getState = () =>
    state;

  /**
   * Updates the state
   * It's the source of truth for catalog filters
   * @param payload New state value
   * @param isQuiet Flag for quiet update
   */
  const update = (payload: SelectedFiltersType, isQuiet: boolean = false) => {
    state = enrichFiltersWithDefaults(payload);
    const newUrl = filtersToUrl(payload);
    if (!newUrl) {
      navigate(window.location.pathname, { replace: true });
    } else {
      navigate(newUrl, { replace: true });
    }
    if (!isQuiet) {
      listeners.forEach((listener: (data: any) => void) =>
        listener(state));
    }
  };

  const subscribe = (listener: (data: any) => void) => {
    listeners.push(listener);

    // unsubscribe function
    return () => {
      listeners = listeners.filter((l) =>
        l !== listener);
    };
  };

  const reset = () => {
    state = urlToFilters();
    listeners.forEach((listener: (data: any) => void) =>
      listener(state));
  };

  return {
    getState, update, subscribe, reset,
  };
};

// Source of truth for catalog filters
export const urlObserver = createUrlObserver();
