import React from "react";

import "./styles.scss";
import { SeoTextProps } from "./types";

const SeoText = ({ text }: SeoTextProps) => {
  if (!text) {
    return <></>;
  }

  return (
    <div className="catalog-seo-text">
      <div
        className="article"
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
    </div>
  );
};

export default SeoText;
