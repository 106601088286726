import React from "react";

import { Link } from "gatsby";

import { filtersToUrl } from "@components/catalog/utils/filterUtils";

import FilterItem from "./filterItem";
import { FilterProps } from "./types";
import "./styles.scss";

const Filter = (props: FilterProps) => {
  const {
    id, name, values, selectedValueIds = [], showSeoLink = false, selectedFilters, onChange,
  } = props;

  return (
    <ul className="filter">
      <div className="filter__name">{name}</div>
      {values.map((value) =>
        (
          <li className="filter__value" key={value.id}>
            {showSeoLink ? (
              <Link
                to={filtersToUrl({ ...selectedFilters, [id]: [value.id] })}
                onClick={(event: React.MouseEvent) => {
                  event.stopPropagation();
                }}
              >
                <FilterItem
                  id={id}
                  name={name}
                  value={value}
                  selectedValueIds={selectedValueIds}
                  onChange={onChange}
                />
              </Link>
            ) : (
              <FilterItem
                id={id}
                name={name}
                value={value}
                selectedValueIds={selectedValueIds}
                onChange={onChange}
              />
            )}
          </li>
        ))}
      {/* todo: hide or implement */}
      {/* <div className="filter__more">Показать ещё</div> */}
    </ul>
  );
};

export default Filter;
