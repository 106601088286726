export const mapSortingIdToRequestValue = (sortingValueId: number | undefined) => {
  switch (sortingValueId) {
    case undefined:
      return {
        sortBy: "rating",
        order: "desc",
      };
    case 0:
      return {
        sortBy: "rating",
        order: "desc",
      };
    case 1:
      return {
        sortBy: "rate",
        order: "asc",
      };
    case 2:
      return {
        sortBy: "rate",
        order: "desc",
      };
    default:
      return undefined;
  }
};
