import _omit from "lodash/omit";

import { Gender, SessionType } from "@/autoGeneratedGlobalTypes";
import { CATALOG_PAGE_NAME } from "@/components/constants";
import { isBrowser } from "@/utils/env";
import { mapSortingIdToRequestValue } from "@/utils/seo/seoUtils";

import { PAGE_SIZE } from "../catalogContainer/constants";
import { DEFAULT_FILTERS } from "../constants";
import { CatalogSeoUrl, ReservedFiltersEnum, SelectedFiltersType } from "../types";

const { catalogUrlMap } = require("../seo/seoData.ts");

// todo: write tests

export const setOrResetTheOnlyValue = (
  currentValueIds: number[],
  changedValueId: number,
): number[] => {
  if (currentValueIds.length === 1 && currentValueIds[0] === changedValueId) {
    return [];
  }
  return [changedValueId];
};

export const updateValue = (currentValueIds: number[], changedValueId: number): number[] => {
  if (currentValueIds.indexOf(changedValueId) !== -1) {
    return currentValueIds.filter((val) =>
      val !== changedValueId);
  }
  return [...currentValueIds, changedValueId];
};

export const enrichFiltersWithDefaults = (filters: SelectedFiltersType) => {
  const newFilters: SelectedFiltersType = { ...filters };
  DEFAULT_FILTERS.forEach((defaultFilter) => {
    if (newFilters[defaultFilter.filter] === undefined) {
      newFilters[defaultFilter.filter] = defaultFilter.values;
    }
  });
  return newFilters;
};

const clearFromDefaults = (filters: SelectedFiltersType) => {
  let newFilters: SelectedFiltersType = { ...filters };
  DEFAULT_FILTERS.forEach((defaultFilter) => {
    if (newFilters[defaultFilter.filter]?.[0] === defaultFilter.values[0]) {
      newFilters = _omit(newFilters, defaultFilter.filter);
    }
  });
  return newFilters;
};

export const urlToFilters = (): SelectedFiltersType => {
  if (!isBrowser()) {
    return DEFAULT_FILTERS.reduce((obj, item) =>
      Object.assign(obj, { [item.filter]: item.values }), {});
  }

  const urlFilters: SelectedFiltersType = {};

  // for paths containing SEO-aproppriate parameters
  const path = window.location.pathname;
  if (path.replace(`/${CATALOG_PAGE_NAME}`, "")) {
    // matching with SEO paths collection
    const matchingUrl = catalogUrlMap.find((su: CatalogSeoUrl) =>
      su.url === path);
    if (matchingUrl) {
      if (matchingUrl.skills) {
        urlFilters[ReservedFiltersEnum.Skill] = matchingUrl.skills;
      }
      if (matchingUrl.scopes) {
        urlFilters[ReservedFiltersEnum.Scope] = matchingUrl.scopes;
      }
    }
  }

  const searchString = window.location.search;
  const urlParams = new URLSearchParams(searchString.replace("?", ""));

  for (const pair of urlParams?.entries() ?? []) {
    urlFilters[pair[0]] = pair[1].split(",").map((v: string) =>
      parseInt(v, 10));
  }

  return enrichFiltersWithDefaults(urlFilters);
};

export const filtersToUrl = (filters: SelectedFiltersType) => {
  const clearedFilters = clearFromDefaults(filters);

  let resultUrl = "";
  switch (true) {
    case (
      clearedFilters[ReservedFiltersEnum.Skill]?.length === 1
      && clearedFilters[ReservedFiltersEnum.Scope]?.length === 1
    ): {
      const matchingUrl = catalogUrlMap.find(
        (seoUrl: CatalogSeoUrl) =>
          seoUrl.skills?.[0] === clearedFilters[ReservedFiltersEnum.Skill][0]
          && seoUrl.scopes?.[0] === clearedFilters[ReservedFiltersEnum.Scope][0],
      );
      if (matchingUrl) {
        resultUrl = matchingUrl.url;
        delete clearedFilters[ReservedFiltersEnum.Skill];
        delete clearedFilters[ReservedFiltersEnum.Scope];
      }
      break;
    }
    case (clearedFilters[ReservedFiltersEnum.Skill]?.length === 1): {
      const matchingUrl = catalogUrlMap.find(
        (seoUrl: CatalogSeoUrl) =>
          !seoUrl.scopes && seoUrl.skills?.[0] === clearedFilters[ReservedFiltersEnum.Skill][0],
      );
      if (matchingUrl) {
        resultUrl = matchingUrl.url;
        delete clearedFilters[ReservedFiltersEnum.Skill];
      }
      break;
    }
    case (clearedFilters[ReservedFiltersEnum.Scope]?.length === 1): {
      const matchingUrl = catalogUrlMap.find(
        (seoUrl: CatalogSeoUrl) =>
          seoUrl.scopes?.[0] === clearedFilters[ReservedFiltersEnum.Scope][0],
      );
      if (matchingUrl) {
        resultUrl = matchingUrl.url;
        delete clearedFilters[ReservedFiltersEnum.Scope];
      }
      break;
    }
    default:
  }

  const resultQueryString = Object.keys(clearedFilters).reduce(
    (result, key) =>
      (clearedFilters[key] && clearedFilters[key].length > 0
        ? `${result ? `${result}&` : ""}${key}=${clearedFilters[key]?.sort().join(",")}`
        : result),
    "",
  );

  return `${resultUrl || catalogUrlMap[0].url}` + `${resultQueryString ? `?${resultQueryString}` : ""}`;
};

const getSessionTypeVariable = (sessionTypeFromFilter: number) => {
  switch (sessionTypeFromFilter) {
    case 1: return SessionType.VIDEO_CALL;
    case 2: return SessionType.PHONE_CALL; // todo for phone calls: move to constants
    case 3: return SessionType.CHAT;
    default: return undefined;
  }
};

// todo: type this with typed graphQL
export const getRequestVariables = (selectedFilters: SelectedFiltersType) =>
  ({
    after: null,
    first: PAGE_SIZE,
    filter: {
      scopes:
          selectedFilters[ReservedFiltersEnum.Scope]?.length > 0
            ? selectedFilters[ReservedFiltersEnum.Scope] : null,
      onlineOnly: selectedFilters[ReservedFiltersEnum.OnlineOnly]?.[0] === 1,
      skills:
          selectedFilters[ReservedFiltersEnum.Skill]?.length > 0
            ? selectedFilters[ReservedFiltersEnum.Skill] : null,
      gender:
        selectedFilters[ReservedFiltersEnum.Gender]?.length === 1
          ? selectedFilters[ReservedFiltersEnum.Gender][0] === 1
            ? Gender.FEMALE
            : Gender.MALE
          : null,
      sessionType:
        selectedFilters[ReservedFiltersEnum.Format]?.length === 1
          ? getSessionTypeVariable(selectedFilters[ReservedFiltersEnum.Format][0])
          : null,
    },
    sorting: mapSortingIdToRequestValue(selectedFilters[ReservedFiltersEnum.Sorting]?.[0] ?? 1),
  });
