import { IconTypeEnum } from "@components/common/icon";

export default [
  {
    type: IconTypeEnum.Family,
    color: "pink",
  },
  {
    type: IconTypeEnum.Purpose,
    color: "yellow",
  },
  {
    type: IconTypeEnum.Work,
    color: "green",
  },
  {
    type: IconTypeEnum.Compatibility,
    color: "pink",
  },
  {
    type: IconTypeEnum.Travel,
    color: "yellow",
  },
  {
    type: IconTypeEnum.Finance,
    color: "green",
  },
  {
    type: IconTypeEnum.Other,
    color: "pink",
  },
  {
    type: IconTypeEnum.Travel,
    color: "yellow",
  },
  {
    type: IconTypeEnum.Compatibility,
    color: "pink",
  },
];
