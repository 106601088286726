import React from "react";

import {
  Button, ButtonColorEnum, ButtonIconPositionEnum, ButtonSizeEnum,
} from "@components/common/button";
import { IconTypeEnum } from "@components/common/icon";

import "./styles.scss";
import { PagingProps } from "./types";

const Paging = (props: PagingProps) => {
  const {
    currentPage, totalCount, pageSize, onChange,
  } = props;

  if (!totalCount || totalCount <= pageSize) {
    return <></>;
  }
  const onPageNumberClick = (pageNumber: number) => {
    onChange(pageNumber);
  };

  const pageCount = Math.ceil(totalCount / pageSize);

  return (
    <ul className="paging">
      <li className={`paging__item paging__item_left${currentPage > 1 ? "" : " hidden"}`}>
        <Button
          text=""
          icon={IconTypeEnum.Dropdown}
          iconPosition={ButtonIconPositionEnum.Left}
          size={ButtonSizeEnum.Middle}
          color={ButtonColorEnum.White}
          onClick={() =>
            onPageNumberClick(currentPage - 1)}
        />
      </li>
      {[...Array(pageCount).keys()].map((index) =>
        (
          <React.Fragment key={index}>
            {currentPage === index + 1 && <li className="paging__item active">{String(index + 1)}</li>}
            <li className="paging__item">
              {currentPage !== index + 1 && (
              <Button
                text={String(index + 1)}
                size={ButtonSizeEnum.Middle}
                color={ButtonColorEnum.White}
                onClick={() =>
                  onPageNumberClick(index + 1)}
              />
              )}
            </li>
          </React.Fragment>
        ))}
      <li className={`paging__item paging__item_right${currentPage < pageCount ? "" : " hidden"}`}>
        <Button
          text=""
          icon={IconTypeEnum.Dropdown}
          iconPosition={ButtonIconPositionEnum.Left}
          size={ButtonSizeEnum.Middle}
          color={ButtonColorEnum.White}
          onClick={() =>
            onPageNumberClick(currentPage + 1)}
        />
      </li>
    </ul>
  );
};

export default Paging;
