import { ReservedFiltersEnum } from "./types";

export const DEFAULT_FILTERS = [
  // By rating
  { filter: ReservedFiltersEnum.Sorting, values: [0] },
  // First page
  { filter: ReservedFiltersEnum.Page, values: [1] },
];

export const DEFAULT_TITLE = "Лучшие эксперты в области Таро, астрологии, нумерологии и рунической мантики | lunaro.ru";
export const DEFAULT_DESCRIPTION = "Выберите эксперта по Таро, чтобы получить исчерпывающий ответ на свой вопрос: любовь, совместимость, финансы, карьера, путешествия, переезды.";
