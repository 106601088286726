import React from "react";

import NightBanner from "./NightBanner";
import NYBanner from "./NYBanner";
import { BannerBlockType, BannerEnum } from "./types";
import { getBannerShowRules, getBannerToShow } from "./utils";
import "./styles.scss";

const BannerBlock = ({ nightBannerData }: BannerBlockType) => {
  const bannerToShow = getBannerToShow(getBannerShowRules(nightBannerData));

  if (!bannerToShow) {
    return null;
  }

  return (
    <>
      {bannerToShow === BannerEnum.NY_BANNER && <NYBanner />}
      {bannerToShow === BannerEnum.NIGHT_BANNER && <NightBanner data={nightBannerData} />}
    </>
  );
};

export default BannerBlock;
